/* outfit-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 200;
  src:
    url('outfit-v11-latin/outfit-v11-latin-200.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('outfit-v11-latin/outfit-v11-latin-200.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* outfit-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  src:
    url('outfit-v11-latin/outfit-v11-latin-300.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('outfit-v11-latin/outfit-v11-latin-300.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* outfit-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  src:
    url('outfit-v11-latin/outfit-v11-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('outfit-v11-latin/outfit-v11-latin-500.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
