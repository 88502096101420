.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-family: 'Outfit';
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Generic margin classes */

.mg_h_10 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mg_h_20 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mg_10 {
  margin: 5px !important;
}

.mg_20 {
  margin: 10px !important;
}

.mb_20 {
  margin-bottom: 20px !important;
}

.mb_40 {
  margin-bottom: 40px !important;
}

.mb-2 {
  margin-bottom: 0.6rem !important;
}

.mb-3 {
  margin-bottom: 1.2rem !important;
}

/* Generic width classes */

.w_10 {
  width: 10%;
}

.w_20 {
  width: 20%;
}

.w_30 {
  width: 30%;
}

.w_40 {
  width: 40%;
}

.w_50 {
  width: 50% !important;
}

.w_60 {
  width: 60%;
}

.w_70 {
  width: 70%;
}

.w_80 {
  width: 80%;
}

.w_90 {
  width: 90%;
}

.w_100 {
  width: 100% !important;
}

canvas {
  max-width: 100%;
}