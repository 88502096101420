body {
  margin: 0;
  font-size: 18px;
  line-height: 26px;
  font-family: -apple-system, BlinkMacSystemFont, 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --fc-button-bg-color: #000;
  --fc-button-border-color: #000;
  --fc-button-hover-bg-color: #c9c7c2;
  --fc-button-hover-border-color: #c9c7c2;
}

.fc-button,
.fc-button:focus {
  outline: none !important;
  box-shadow: none !important;
  height: 55px !important;
}
@media (min-width: 768px) {
  .fc-button,
  .fc-button:focus {
    height: 51px !important;
  }
}
